export const STORE_LOGIN_STATE = 'STORE_LOGIN_STATE';
export const STORE_TOKEN = 'STORE_TOKEN';

export const setUser = (payload) => {
  return {
    type: STORE_LOGIN_STATE,
    payload: payload,
  };
};

export const setToken = (payload) => {
  return {
    type: STORE_TOKEN,
    payload: payload,
  };
};
