import { SET_WEB3_PROVIDER } from '../actions/setweb3Provider.actions';

const providerMetadata = null;

const setweb3Provider = (state = providerMetadata, action) => {
  switch (action.type) {
    case SET_WEB3_PROVIDER:
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default setweb3Provider;
