import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './assets/css/assignFields.css';
const Post = React.lazy(() => import('./components/blocks/Post'));
const Posts = React.lazy(() => import('./components/blocks/Posts'));
const SecuredRoute = React.lazy(() =>
  import('./components/common/SecuredRoute')
);
const WelcomePageWrapper = React.lazy(() =>
  import('./components/common/WelcomePageWrapper')
);
const About = React.lazy(() => import('./components/pages/About'));
const Blog = React.lazy(() => import('./components/pages/Blog'));
const ConfirmEmail = React.lazy(() =>
  import('./components/pages/ConfirmEmail')
);
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Dashaboard = React.lazy(() => import('./components/pages/Dashaboard'));
const Documentations = React.lazy(() =>
  import('./components/pages/Documentations')
);
const Faq = React.lazy(() => import('./components/pages/Faq'));
const Features = React.lazy(() => import('./components/pages/Features'));
const Forgotpassword = React.lazy(() =>
  import('./components/pages/Forgotpassword')
);
const Home = React.lazy(() => import('./components/pages/Home'));
// const Login = React.lazy(() => import('./components/pages/Login'));
const PageNotFound = React.lazy(() =>
  import('./components/pages/PageNotFound')
);
const PreviewFields = React.lazy(() =>
  import('./components/pages/PreviewFields')
);
const Signup = React.lazy(() => import('./components/pages/Signup'));
const VerifyEmail = React.lazy(() => import('./components/pages/VerifyEmail'));

const DashboardPageWrapper = React.lazy(() =>
  import('./components/common/DashboardPageWrapper')
);
const AssignFields = React.lazy(() =>
  import('./components/pages/AssignFields')
);
const BlockchainDocPreview = React.lazy(() =>
  import('./components/pages/BlockchainDocPreview')
);
const BlockchainDocPreviewSingle = React.lazy(() =>
  import('./components/pages/BlockchainDocPreviewSingle')
);
const CreateFields = React.lazy(() =>
  import('./components/pages/CreateFields')
);
const CreateNewPassword = React.lazy(() =>
  import('./components/pages/CreateNewPassword')
);
const DocPreview = React.lazy(() => import('./components/pages/DocPreview'));
const DocPreviewCombinedSigners = React.lazy(() =>
  import('./components/pages/DocPreviewCombinedSigners')
);
const DocPublishing = React.lazy(() =>
  import('./components/pages/DocPublishing')
);
const DocSign = React.lazy(() => import('./components/pages/DocSign'));
const Documents = React.lazy(() => import('./components/pages/Documents'));
const PrivacyPolicy = React.lazy(() =>
  import('./components/pages/PrivacyPolicy')
);
const Profile = React.lazy(() => import('./components/pages/Profile'));
const Payment = React.lazy(() => import('./components/pages/Payment'));
const P2P = React.lazy(() => import('./components/pages/P2P'));
const Recurring = React.lazy(() => import('./components/pages/Recurring'));
const Escrow = React.lazy(() => import('./components/pages/Escrow'));
const Web3auth = React.lazy(() => import('./components/pages/Web3auth'));
const web3RPC = React.lazy(() => import('../src/web3RPC'));
const Web3auth2 = React.lazy(() => import('./components/pages/Web3auth2'));

const PublishedDocs = React.lazy(() =>
  import('./components/pages/PublishedDocs')
);
const SelectSigners = React.lazy(() =>
  import('./components/pages/SelectSigners')
);
const SharedDoc = React.lazy(() => import('./components/pages/SharedDoc'));
const SignDocBlockchain = React.lazy(() =>
  import('./components/pages/SignDocBlockchain')
);
const SignersForBlockchainFlow = React.lazy(() =>
  import('./components/pages/SignersForBlockchainFlow')
);
const TermsAndConditions = React.lazy(() =>
  import('./components/pages/TermsAndConditions')
);
const VerifyAccount = React.lazy(() =>
  import('./components/pages/VerifyAccount')
);
const ViewPublishedDoc = React.lazy(() =>
  import('./components/pages/ViewPublishedDoc')
);
const ViewSharedDoc = React.lazy(() =>
  import('./components/pages/ViewSharedDoc')
);
const Testimonial = React.lazy(() => import('./components/pages/Testimonial'));
const VerifySignature = React.lazy(() =>
  import('./components/common/VerifySignature')
);
const ViewBlockchainDocs = React.lazy(() =>
  import('./components/pages/ViewBlockchainDocs')
);
const Solutions = React.lazy(() => import('./components/pages/Solutions'));
const ViewSigningDocs = React.lazy(() =>
  import('./components/pages/ViewSigningDocs')
);
const PaymentView = React.lazy(() =>
  import('./components/pages/PaymentDetailsView.jsx')
);

function App() {
  const environment = process?.env?.REACT_APP_ENVIRONMENT;
  if (environment === 'production' || environment === 'devserver') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  return (
    <>
      <Suspense fallback={<div>.</div>}>
        <Router>
          <Routes>
            <Route element={<WelcomePageWrapper />}>
              <Route element={<Home />} path="/" />
              <Route element={<Features />} path="/features" />
              <Route element={<Documentations />} path="/documentations" />
              <Route element={<Faq />} path="/faq" />
              <Route element={<Solutions />} path="/solutions" />
              <Route element={<About />} path="/about" />
              <Route element={<Testimonial />} path="/Testimonial" />
              <Route element={<Signup />} path="/signup" />
              <Route element={<Forgotpassword />} path="/forgotpassword" />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route element={<Contact />} path="/contact" />
              <Route element={<Blog />} path="/blog">
                <Route element={<Posts />} path="" />
                <Route element={<Post />} path=":postSlug" />
              </Route>
              <Route element={<VerifyEmail />} path="/verifyEmail" />
              <Route element={<ConfirmEmail />} path="/confirm" />
              <Route element={<CreateNewPassword />} path="/resetPassword" />
              <Route element={<VerifyAccount />} path="/AccountVerify" />
              <Route element={<PrivacyPolicy />} path="/privacyPolicy" />
              <Route
                element={<TermsAndConditions />}
                path="/terms-and-conditions"
              />
            </Route>

            {/* loggedIn Routes */}
            <Route element={<SecuredRoute />}>
              <Route element={<DashboardPageWrapper />}>
                <Route element={<Dashaboard />} path="/dashboard" />
                <Route element={<PreviewFields />} path="/preview" />
                <Route element={<SelectSigners />} path="/selectSigners" />
                <Route
                  element={<SignersForBlockchainFlow />}
                  path="/SignersForBlockchainFlow"
                />
                <Route element={<DocPublishing />} path="/publishDoc" />
                <Route element={<SharedDoc />} path="/shareDoc" />
                <Route element={<Documents />} path="/docs" />
                <Route element={<Profile />} path="/profile" />
                <Route element={<Payment />} path="/payment" />
                <Route element={<P2P />} path="/p2p" />
                <Route element={<Recurring />} path="/recurring" />
                <Route element={<Escrow />} path="/escrow" />
                <Route element={<Web3auth />} path="/web3auth" />
                <Route element={<web3RPC />} path="/web3RPC" />
                <Route element={<Web3auth2 />} path="/web3auth2" />
              </Route>

              <Route element={<AssignFields />} path="/assignFields" />
              <Route element={<CreateFields />} path="/createFields" />
              <Route element={<DocSign />} path="/signDoc/:id" />
              <Route
                element={<SignDocBlockchain />}
                path="/signDocBlockchain/:id"
              />
              <Route element={<DocPreview />} path="/docPreview/:id" />
              <Route
                element={<BlockchainDocPreview />}
                path="/BlockchainDocPreview/:id"
              />
              <Route
                element={<DocPreviewCombinedSigners />}
                path="/DocPreviewCombinedSigners/:id"
              />

              <Route
                element={<BlockchainDocPreviewSingle />}
                path="/BlockchainDocSinglePreview/:id"
              />
              <Route element={<ViewSharedDoc />} path="/viewSharedDoc/:id" />
              <Route
                element={<ViewBlockchainDocs />}
                path="/viewBlocakchainDoc/:id"
              />
              <Route element={<ViewSigningDocs />} path="/viewSigningDoc/:id" />
              <Route element={<VerifySignature />} path="/verifySignature" />
              <Route element={<PaymentView />} path="/payment/details/:id" />
            </Route>
            {/* End of loggedIn Routes */}
            <Route element={<PageNotFound />} path="*" />
            {/* public routes */}
            <Route element={<PublishedDocs />} path="/published-docs" />
            <Route element={<ViewPublishedDoc />} path="/published-docs/:id" />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}
export default App;
