import { SET_WEB3 } from '../actions/setweb3.actions';

const web3Metadata = null;

const setweb3Reducer = (state = web3Metadata, action) => {
  switch (action.type) {
    case SET_WEB3:
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default setweb3Reducer;
