import { SET_PUBLISHING_PDFS } from '../actions/docPublishing.action';

const initialState = {
  fileList: null,
};

const docPublishingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PUBLISHING_PDFS:
      return { ...state, fileList: action.payload };

    default:
      return state;
  }
};

export default docPublishingReducer;
