export const KEY_USER = 'meafjnHZVw0';
export const KEY_TOKEN = 'abbdJ4Yfm54';

export const DOCUMENT_SIGNING = 'Document Signing';
export const DOCUMENT_SIGNING_WITH_BLOCKCHAIN =
  'Document Signing with Blockchain';
export const DOCUMENT_PUBLISHING = 'Document Publishing';
export const DOCUMENT_SHARING = 'Document Sharing';

export const ALERT_MESSAGE_TEXT = 'ALERT_MESSAGE_TEXT';

export const FieldTypes = {
  TEXT_INPUT: 'Text Inputs',
  DATES: 'Dates',
  TAGS: 'Tags',
  INITIALS: 'Initials',
  SIGNATURE: 'Signatures',
  CHECKBOX: 'CheckBox',
};

export const FontList = [
  {
    id: 1,
    name: 'Arial',
    fontFamily: 'Arial',
  },
  {
    id: 2,
    name: 'Calibri',
    fontFamily: 'calibri',
  },
  {
    id: 3,
    name: 'Roboto',
    fontFamily: 'roboto',
  },
  {
    id: 4,
    name: 'Verdana',
    fontFamily: 'verdana',
  },
];

export const FontSize = [
  {
    id: 1,
    size: 12,
  },
  {
    id: 2,
    size: 14,
  },
  {
    id: 3,
    size: 16,
  },
  {
    id: 4,
    size: 18,
  },
  {
    id: 5,
    size: 20,
  },
  {
    id: 6,
    size: 22,
  },
];

export const SIGNER_COLORS = [
  '#ccdafd',
  '#b0c7ff',
  '#a3beff',
  '#9bb8fd',
  '#8dacf7',
  '#7fa3f9',
  '#759cf9',
  '#6592fd',
  '#5183fb',
];

export const KEY_METAMASK_INFO = 'KEY_METAMASK_INFO';
