import { combineReducers, createStore } from 'redux';

import authReducer from './reducers/auth.reducers';
import docPublishingReducer from './reducers/docPublishing.reducers';
import pdfUploadReducer from './reducers/pdfupload.reducers';
import receiverMetadataReducer from './reducers/receiverMetadata.reducer';
import settingsReducer from './reducers/settings.reducers';
import signingMetadataReducer from './reducers/signingMetadata.reducers';
import setweb3Reducer from './reducers/setweb3Reducer.reducers';
import setweb3Provider from './reducers/setweb3Provider.reducers';
const rootReducer = combineReducers({
  authReducer,
  settingsReducer,
  pdfUploadReducer,
  signingMetadataReducer,
  receiverMetadataReducer,
  docPublishingReducer,
  setweb3Reducer,
  setweb3Provider,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
