import { TOP_LOADIND_BAR } from '../actions/settings.actions';

const initialState = {
  topLoadingBarRef: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOP_LOADIND_BAR:
      return { ...state, topLoadingBarRef: action.payload };

    default:
      return state;
  }
};

export default settingsReducer;
